<template>
  <div>
    <send-email-modal :show-modal="emailDetails.emailModalOpen" :to="emailDetails.email"
      :ok="(data) => sendStudentMail(data)" :close="closeEmailModal" />

    <send-email-modal :show-modal="bulkEmailDetails.emailModalOpen" :to="bulkEmailDetails.email"
      :ok="(data) => sendBulkMail(data)" :close="closeEmailModal" />
    <b-modal :no-close-on-backdrop="true" id="add-student-modal" ref="add-student-modal" centered hide-footer>
      <validation-observer ref="addStudentForm">
        <b-form class="auth-register-form mt-2">
          <div>
            <b-form-group label="First name" label-for="register-firstname">
              <validation-provider #default="{ errors }" name="Firstname" rules="required">
                <b-form-input id="register-firstname" name="register-firstname"
                  :state="errors.length > 0 ? false : null" placeholder="First name" v-model="student_form.firstname" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Last name" label-for="register-lastname">
              <validation-provider #default="{ errors }" name="Lastname" rules="required">
                <b-form-input id="register-lastname" name="register-lastname" :state="errors.length > 0 ? false : null"
                  placeholder="Last Name" v-model="student_form.lastname" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Phone number" label-for="register-mobile">
              <validation-provider #default="{ errors }" name="Mobile Number" rules="required">
                <b-form-input id="register-mobile" type="number" name="register-mobile"
                  :state="errors.length > 0 ? false : null" placeholder="+91XXXXXXXXXX" v-model="student_form.mobile" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Email" label-for="register-email">
              <validation-provider #default="{ errors }" name="Email Address" rules="required">
                <b-form-input id="register-email" :state="errors.length > 0 ? false : null" name="register-email"
                  v-model="student_form.email" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group label-for="register-password" label="Password">
              <validation-provider #default="{ errors }" name="Email Address" rules="required">
                <b-input-group class="input-group-merge">
                  <b-form-input id="register-password" class="form-control-merge rp_padding"
                    :state="errors.length > 0 ? false : null" name="register-password" :type="passwordFieldType"
                    placeholder="············" v-model="student_form.password" />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <b-input-group-append is-text>
                    <feather-icon :icon="passwordToggleIcon" class="cursor-pointer" @click="togglePasswordVisibility" />
                  </b-input-group-append>
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="text-right">
            <b-button @click="closeModal" variant="outline-danger mr-2">
              Close
            </b-button>
            <b-button variant="outline-primary" type="submit" @click.prevent="addStudent">
              Submit
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal :no-close-on-backdrop="true" v-model="show_working_invoice" title="Working Invoice Details" hide-footer
      @ok="" size="lg">
      <b-container v-if="workingInvoice">
        <b-row class="mb-2 align-items-center">
          <b-col cols="6">
            <label>First Name</label>
            <p>{{ workingInvoice.user_firstname }}</p>
          </b-col>
          <b-col cols="6">
            <label>Last Name</label>
            <p>{{ workingInvoice.user_lastname }}</p>
          </b-col>
        </b-row>
        <b-row class="mb-2 align-items-center">
          <b-col cols="6">
            <label>University Name</label>
            <p>{{ workingInvoice.university_name }}</p>
          </b-col>
          <b-col cols="6">
            <label>Course Name</label>
            <p>{{ workingInvoice.course_name }}</p>
          </b-col>
        </b-row>
        <b-row class="mb-2 align-items-center">
          <b-col cols="6">
            <label>Intake Month</label>
            <p>{{ workingInvoice.intake_month }}</p>
          </b-col>
          <b-col cols="6">
            <label>Course Fee (Per Annum)</label>
            <p>{{ workingInvoice.course_fee_pa }}</p>
          </b-col>
        </b-row>
        <b-row class="mb-2 align-items-center">
          <b-col cols="6">
            <label>Scholarship Amount</label>
            <b-form-input :value="workingInvoice.scholarship_amount" disabled></b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Commission Currency</label>
            <b-form-input :value="workingInvoice.currency" disabled></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2 align-items-center">
          <b-col v-if="workingInvoice.commission && workingInvoice.commission.topup_percent">
            <label>My Commission (%)</label>
            <b-form-input :value="workingInvoice.commission.topup_percent" disabled></b-form-input>
          </b-col>
          <b-col v-else>
            <label>Market Commission (%)</label>
            <b-form-input :value="workingInvoice.crm_percent" disabled></b-form-input>
          </b-col>

          <b-col cols="6" v-if="user_type === 'accounts'">
            <label>Bank Account</label>
            <v-select v-model="workingInvoice.ba_id" v-if="bank_accounts && bank_accounts.length"
              :options="bank_accounts" label="ba_name" :reduce="(item) => item.ba_id" />
            <span v-else>No Bank Accounts Available</span>
          </b-col>

        </b-row>

        <b-row class="mb-2 align-items-center">
          <b-col cols="6">
            <label>Commission Amount (in {{ workingInvoice.currency }})</label>
            <b-form-input v-model="workingInvoice.commission.total_commission" @input="onCommissionType"></b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Commission Amount (in INR)</label>
            <b-form-input v-model="workingInvoice.commission.inr_commission" @input="onCommissionType"></b-form-input>
          </b-col>

        </b-row>


        <b-row class="mb-2 align-items-center">
          <b-col v-if="workingInvoice.bonus_amount">
            <label>Bonus Commission(Amount)</label>
            <b-form-input :value="workingInvoice.bonus_amount" disabled></b-form-input>
          </b-col>
          <b-col v-if="workingInvoice.bonus_percent">
            <label>Bonus Commission (%)</label>
            <b-form-input :value="workingInvoice.bonus_percent" disabled></b-form-input>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button @click="closeModal" variant="outline-danger mr-2">
            Close
          </b-button>
          <b-button variant="outline-success" v-if="workingInvoice.its_id === 1 && user_type === 'accounts'"
            type="submit" @click.prevent="updateInvoice(workingInvoice)">
            Approve
          </b-button>
          <b-button variant="outline-success" v-if="workingInvoice.its_id === 4 "
            type="submit" @click.prevent="updateFranchiseInvoice(workingInvoice)">
            Approve
          </b-button>
        </div>
      </b-container>
    </b-modal>
    <b-modal :no-close-on-backdrop="true" v-model="show_franchise_invoice" title="Working Invoice Details" hide-footer
      @ok="" size="lg">
      <b-container v-if="workingInvoice">
        <b-row class="mb-2 align-items-center">
          <b-col cols="6">
            <label>First Name</label>
            <p>{{ workingInvoice.user_firstname }}</p>
          </b-col>
          <b-col cols="6">
            <label>Last Name</label>
            <p>{{ workingInvoice.user_lastname }}</p>
          </b-col>
        </b-row>
        <b-row class="mb-2 align-items-center">
          <b-col cols="6">
            <label>University Name</label>
            <p>{{ workingInvoice.university_name }}</p>
          </b-col>
          <b-col cols="6">
            <label>Course Name</label>
            <p>{{ workingInvoice.course_name }}</p>
          </b-col>
        </b-row>
        <b-row class="mb-2 align-items-center">
          <b-col cols="6">
            <label>Intake Month</label>
            <p>{{ workingInvoice.intake_month }}</p>
          </b-col>
          <b-col cols="6">
            <label>Course Fee (Per Annum)</label>
            <p>{{ workingInvoice.course_fee_pa }}</p>
          </b-col>
        </b-row>
        <b-row class="mb-2 align-items-center">
          <b-col cols="6">
            <label>Scholarship Amount</label>
            <b-form-input :value="workingInvoice.scholarship_amount" disabled></b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Commission Currency</label>
            <b-form-input :value="workingInvoice.currency" disabled></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-2 align-items-center">
          <b-col>
            <label>Market Commission (%)</label>
            <b-form-input :value="workingInvoice.crm_percent" disabled></b-form-input>
          </b-col>
          <b-col cols="6">
            <label>Commission Amount</label>
            <b-form-input :value="Math.floor(workingInvoice.commission.franchise_commission)" @input="onCommissionType"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-2 align-items-center" v-if="workingInvoice.its_id === 5 && workingInvoice.commission.franchise_commission_inr">
          <b-col>
            <label>Partner Commission</label>
            <p>{{ workingInvoice.commission.franchise_commission_inr }}</p>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button @click="closeModal" variant="outline-danger mr-2">
            Close
          </b-button>
            <b-button :disabled="workingInvoice.its_id === 6" variant="outline-success" type="submit" @click.prevent="updateFranchiseInvoice(workingInvoice)">
              {{ workingInvoice.its_id === 4 ? 'Update' : (workingInvoice.its_id === 5 ? 'Paid to Partner' : (workingInvoice.its_id === 6 ? 'Paid' : 'Approve')) }}
            </b-button>
        </div>
      </b-container>
    </b-modal>

    <b-card>
      <b-col class="m-2 w-100">
        <!-- Table Filters -->

        <b-row class="mr-3 mb-2 align-items-end">
          <b-col class="col-md-7">
            <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
              @input="onSearch" />
          </b-col>
          <b-col>
            <label>Per Page</label>
            <v-select v-model="pagination.perPage" :options="pagination.perPageOptions" :clearable="false"
              class="per-page-selector d-inline-block w-100" @input="onSearch" />
          </b-col>
          <!-- <b-col>
            <label>Completion Status</label>
            <v-select v-model="filters.service_completion_status" :options="[{label: 'Completed', value: 1}, {label: 'Pending', value: 0}]" :reduce="(item) => item.value" :clearable="true"
              class="per-page-selector d-inline-block w-100" @input="onSearch" />
          </b-col> -->
          <!-- Per Page -->

          <!-- Status -->
          <b-col class="
              d-flex
              align-items-start
              justify-content-end
              flex-column
              mb-1 mb-md-0
            "
            v-if="user_type !== 'services'">
            <label>Status</label>
            <v-select v-model="filters.student_status" :options="student_status_options" :clearable="true"
              item-text="label" :reduce="(item) => item.value" class="per-page-selector d-inline-block w-100"
              @input="onSearch" />
          </b-col>
          <!-- Status -->
        </b-row>
        <b-row class="mr-3 mb-2 align-items-end">
          <!-- <b-col
            class="d-flex align-items-start justify-content-center flex-column mb-1 mb-md-0 p-0"
          >
            <label>Per Page</label>
            <v-select
              v-model="pagination.perPage"
              :options="pagination.perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block w-100"
              @input="onSearch"
            />
          </b-col> -->

          <!-- Status -->

          <!-- Conversion -->
          <b-col class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="[ 'counselor','franchise'].includes(user_type)">
            <label>Conversion</label>
            <v-select v-model="filters.conversion" :options="conversion_status_options" :clearable="true"
              item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>
          <!-- Counsellor Wise Filter -->
          <b-col class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="!['services'].includes(user_type)">
            <label>Counselor</label>
            <v-select v-model="filters.counselor_id" :options="counselor_options.map((counselor) => ({
              value: counselor.user_id,
              label: `${counselor.user_firstname} ${counselor.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>
          <b-col class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="['operations_manager'].includes(user_type)">
            <label>Counselor</label>
            <v-select v-model="filters.counselor_id" :options="op_counselors.map((counselor) => ({
              value: counselor.user_id,
              label: `${counselor.user_firstname} ${counselor.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <b-col class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="
              [
                'operations_manager',
                'counselor',
                'operations',
                'visa',
                // 'services',
                'accounts',
              ].includes(user_type)
            ">
            <label>Franchise</label>
            <v-select v-model="filters.franchise_id" :options="op_franchises.map((franchise) => ({
              value: franchise.user_id,
              label: `${franchise.user_firstname} ${franchise.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>
          <b-col v-if = "['services'].includes(user_type)">
            <label>Services</label>
            <v-select v-model="filters.service_id" :options="services_list" :clearable="false"
              :reduce="(item) => item.service_id" label="service_name" class="per-page-selector d-inline-block w-100"
              @input="onSearch" />
          </b-col>

          <b-col class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="['accounts'].includes(user_type)">
            <label>Application Status</label>
            <v-select v-model="filters.application_status_id" :options="application_statuses.map((app) => ({
              value: app.status_id,
              label: `${app.status_name}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <!-- <b-col class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="['accounts'].includes(user_type)">
            <label>Intake Month</label>
            <v-select v-model="filters.intake_month" :reduce="(item) => item"
              class="per-page-selector d-inline-block w-100" :options="all_months" @input="onSearch" />
          </b-col>

          <b-col class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="['accounts'].includes(user_type)">
            <label>Invoice Status</label>
            <v-select v-model="filters.its_id" label="its_name" :reduce="(item) => item.its_id"
              class="per-page-selector d-inline-block w-100" :options="invoice_statuses" @input="onSearch" />
          </b-col> -->

          <b-col class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="
              [
                'operations_manager',
                'counselor',
                'operations',
                'visa',
              ].includes(user_type)
            ">
            <label>Associate</label>
            <v-select v-model="filters.agent_id" :options="op_agents.map((agent) => ({
              value: agent.user_id,
              label: `${agent.user_firstname} ${agent.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <b-col class="
              d-ex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="['operations_manager'].includes(user_type)">
            <label>Operations</label>
            <v-select v-model="filters.operations_id" :options="op_operations.map((counselor) => ({
              value: counselor.user_id,
              label: `${counselor.user_firstname} ${counselor.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <b-col class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            " v-if="['operations_manager'].includes(user_type)">
            <label>Visa</label>
            <v-select v-model="filters.visa_id" :options="op_visa.map((counselor) => ({
              value: counselor.user_id,
              label: `${counselor.user_firstname} ${counselor.user_lastname}`,
            }))
              " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
              class="per-page-selector d-inline-block w-100" />
          </b-col>

          <b-col v-if="
            [
              // 'operations',
              'counselor',
              // 'franchise',
              'student_support',
            ].includes(user_type)
          " class="
              d-flex
              mb-1 mb-md-0
              align-items-start
              justify-content-center
              flex-column
            ">
            <label>Lead Type</label>
            <v-select v-model="leadType" :options="leadTypeOptions" :clearable="true" item-text="label"
              :reduce="(item) => item.value" class="per-page-selector d-inline-block w-100" @input="() => {
                leadSource = null;
                onSearch();
              }
                " />
          </b-col>
          <b-col v-if="
            [
              // 'operations',
              'counselor',
              // 'franchise',
              'student_support',
            ].includes(user_type)
          " class="
              d-flex
              align-items-start
              justify-content-center
              flex-column
              mb-1 mb-md-0
            ">
            <label>Lead Source</label>
            <v-select v-model="leadSource" :disabled="leadType ? false : true"
              :options="getCurrentSourceOptions(leadType)" :clearable="true" item-text="label"
              :reduce="(item) => item.value" class="per-page-selector d-inline-block w-100" @input="onSearch" />
          </b-col>

          <b-col class="
              d-flex
              align-items-start
              justify-content-center
              flex-column
              mb-1 mb-md-0
            ">
            <label>Assigned on Date Range</label>
            <flat-pickr id="assigned_on" placeholder="Select Date Range" v-model="dateRange" class="form-control"
              :config="{
                dateFormat: 'Y-m-d',
                altInput: true,
                altFormat: 'd-m-Y',
                allowInput: true,
                mode: 'range',
              }" />
          </b-col>
          <b-col class="
              d-flex
              align-items-start
              justify-content-center
              flex-column
              mb-1 mb-md-0
            ">
            <button class="btn btn-primary w-100" @click="resetFilters">
              Reset
            </button>
          </b-col>
        </b-row>
        <b-row cols="12 mb-2 d-flex justify-content-end">
          <b-col md="2" class="
              d-flex
              align-items-end
              justify-content-center
              mb-1 mb-md-0
              pr-2
            ">
            <!-- <button
              class="btn btn-primary mr-1 w-100"
              @click="getAssignedStudentsList()"
            >
              Search
            </button> -->
            <!-- <button class="btn btn-primary w-100" @click="resetFilters">
              Reset
            </button> -->
          </b-col>
        </b-row>
      </b-col>
    </b-card>
    <b-card class="mb-0">
      <div v-if="['agent', 'franchise'].includes(user_type)" class="m-2 d-flex justify-content-between align-items-center">
        <div class="text-muted"> {{ totalStudentCount }} students </div>

        <b-button variant="outline-primary" v-b-modal.add-student-modal>Add Student</b-button>
      </div>
      <div v-if="show_bulk_actions" class="w-100">
        <div class="
            border-top border-bottom
            d-flex
            align-items-center
            justify-content-center
            py-1
            mb-1
          " style="gap: 20px;">
          <b-button variant="primary" style="width: fit-content" @click="showBulkEmailModal">
            Send Bulk Mail
          </b-button>
          <b-button v-if="user_type === 'accounts' || user_type === 'franchise'" variant="primary"
            style="width: fit-content" @click="getClubbedInvoice">
            Club Invoice
          </b-button>
        </div>
      </div>
      <!-- Student List -->
      <b-table id="StudentsListTableId" ref="refStudentsListTable" class="position-relative" :items="studentsList"
        responsive :fields="tableColumns" primary-key="student_user_id" show-empty
        empty-text="No matching records found" @row-clicked="row_clicked" selectable>
        <template #head(user_id)="data">
          <div class="d-flex align-items-center" style="gap: 20px">
            <div style="width: 20px">
              <b-form-checkbox v-model="select_all" @change="selectOrDeselectAll" />
            </div>
            ID
          </div>
        </template>
        <template #cell(user_id)="data">
          <div class="d-flex align-items-center" style="gap: 20px">
            <div style="width: 20px; margin-right: 5px">
              <b-form-checkbox v-model="data.item.is_selected" @change="() => selectOrDeselectSingle(data.item)" />
            </div>
            {{ data.item.user_id }}
            <!-- {{ selected_lead_ids }}
            {{ selected_lead_emails }}
            {{ selectable_lead_ids }} -->
            <!-- {{ studentsList }} -->
          </div>
        </template>
        <template #cell(user_name)="data">
          <b-media vertical-align="center">
            <template #aside>
              <div class="d-flex align-items-center">
                <b-avatar size="32" :src="FILESURL + data.item.user_profile_image" />
              </div>
            </template>
            <p>
              {{ data.item.user_name }}
              <span v-if="
                data.item.plan_name_with_service &&
                ['accounts'].includes(user_type)
              ">
                <b-badge variant="success">{{
                  data.item.plan_name_with_service
                }}</b-badge>
              </span>
              <span v-if="data.item.el_name && ['services'].includes(user_type)">
                <b-badge variant="success">{{ data.item.el_name }}</b-badge>
              </span>
            </p>
          </b-media>
        </template>

        <template #cell(user_mobile)="data">
          <div style="width: max-content" v-if="data.value">
            {{ data.value }}
            <a target="_blank" :href="`https://wa.me/${getWhatsappFormattedMobile(data.value)}`">
              <img src="/whatsapp.webp" alt="" style="width: 25px; height: 25px" />
            </a>
          </div>
        </template>

        <template #cell(student_applications)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ student_applications_count(data.item.applied_course_ids) }}
            </span>
          </div>
        </template>

        <template #cell(active_applications)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{
                student_applications_count(data.item.active_applied_course_ids)
              }}
            </span>
          </div>
        </template>
        f5f5f5
        <template #cell(student_status)="data">
          <div class="text-nowrap" style="width: max-content">
            <span v-if="['counselor', 'visa', 'operations'].includes(user_type)">
              <b-form-select class="text-capitalize" :options="['hold', 'joined', 'active', 'inactive']"
                :value="data.item.student_status" @input="setStudentStatus($event, data.item)" />
            </span>
            <span v-else class="align-text-top text-capitalize">
              {{ data.item.student_status }}
            </span>
          </div>
        </template>

        <template #cell(conversion_date)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ conversion_date_fn(data.item.conversion_date) }}
            </span>
          </div>
        </template>

        <template #cell(transaction_amount)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              <span>{{ data.item.currency }}</span>
              <span>{{ data.item.transaction_amount }}</span>
            </span>
          </div>
        </template>

        <template #cell(university_commission)="data">
          <div class="text-nowrap">
            <b-form-input v-model="data.item.university_commission" @input="
              onType(
                data.item.student_user_id,
                data.item.university_commission,
                data.item.franchise_commission
              )
              " />
          </div>
        </template>

        <!-- Template cell for franchise_commission -->
        <template #cell(franchise_commission)="data">
          <div class="text-nowrap">
            <b-form-input v-model="data.item.franchise_commission" @input="
              onType(
                data.item.student_user_id,
                data.item.university_commission,
                data.item.franchise_commission
              )
              " />
          </div>
        </template>

        <template #cell(fr_commission_date)="data">
          <div class="text-nowrap">
            <!-- <b-form-select class="text-capitalize" :options="['hold', 'joined', 'active', 'inactive']"
                :value="data.item.student_status" @input="setStudentStatus($event, data.item)" /> -->

                <flat-pickr
    v-model="data.item.fr_commission_date"
    class="form-control"
    placeholder=" Date"
    @input="
              onType(
                data.item.student_user_id,
                null,
                null,
                data.item.fr_commission_date,
              )
              " 
    :config="{
      dateFormat: 'Y-m-d H:i',
      altInput: true,
      altFormat: 'd-m-Y H:i',
      allowInput: true,
      noCalendar: false,
    }"
  />
          </div>
        </template>

        <!-- Template cell for franchise_commission -->
        <template #cell(service_commission)="data">
          <div class="text-nowrap">
            <b-form-input v-model="data.item.service_commission" @input="
              onType(data.item.student_user_id, data.item.service_commission)
              " />
          </div>
        </template>

        <template #cell(assigned_staff)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.assigned_staff_name }}
            </span>
          </div>
        </template>

        <template #cell(operations_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.operations_name }}
            </span>
          </div>
        </template>

        <template #cell(transaction_datetime)="data">
          <div style="width: max-content" v-if="data.item.transaction_datetime">
            {{
              moment(data.item.transaction_datetime).format(
                "DD MMM, YYYY - hh:mm A"
              ) || "-"
            }}
          </div>
          <div style="width: max-content" v-else>-</div>
        </template>

        <template #cell(visa_name)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">
              {{ data.item.visa_name }}
            </span>
          </div>
        </template>

        <template #cell(student_source_type)="data">
          <div class="text-nowrap" style="width: max-content">
            <v-select v-if="['counselor'].includes(user_type)" v-model="data.item.source_type"
              :options="leadTypeOptions" :clearable="true" item-text="label" :reduce="(item) => item.value"
              @input.prevent="
                {
                saveLead(
                  data.item.student_user_id,
                  data.item.application_limit,
                  data.item.source_type
                );
                // handleLeadSourceOptions();
              }
                " class="per-page-selector d-inline-block w-100" style="min-width: 200px" />
            <p v-else>
              {{ getLeadTypeFromKey(data.item.source_type) }}
            </p>
          </div>
        </template>

        <template #cell(student_source)="data">
          <div class="text-nowrap" style="width: max-content">
            <v-select :value="data.item.source_user_id
              ? data.item.source_user_id
              : data.item.source
              " v-if="['counselor'].includes(user_type)" :disabled="data.item.source_type ? false : true"
              :options="getCurrentSourceOptions(data.item.source_type)" :clearable="true" item-text="label"
              style="min-width: 200px" :reduce="(item) => item.value" @input.prevent="(value) =>
                saveLead(
                  data.item.student_user_id,
                  data.item.application_limit,
                  data.item.source_type,
                  value
                )
                " />
            <p v-else>
              {{
                data.item.source_user_id
                  ? data.item.source_user
                  : getDirectSourceByValue(data.item.source)
              }}
            </p>
          </div>
        </template>
        <!-- <template #cell(creator)="data">
          <b-media vertical-align="center" style="width: max-content;">
            {{
              data.item.creator_user_id
                ? data.item.creator_firstname + " " + data.item.creator_lastname
                : "website"
            }}
          </b-media>
        </template> -->
        <template #cell(uni_invoice)="data">
          <div v-if="user_type === 'accounts' && (data.item.invoices && data.item.invoices.its_id >= 1)" class="d-flex" style="gap: 20px;">
            <b-button style="
                background-color: transparent !important;
                border: none !important;
              " title="Invoice" @click="showWorkingInvoice(data.item.student_user_id, 'accounts')"><feather-icon
                icon="FileIcon" size="18" /></b-button>
          </div>
        </template>

        <template #cell(fr_invoice)="data">
          <b-button
          v-if="data.item.invoices && data.item.invoices.its_id >= 4"
          style="
                background-color: transparent !important;
                border: none !important;
              " title="Invoice" @click="showFranchiseWorkingInvoice(data.item.student_user_id)"><feather-icon
              icon="FileIcon" size="18" /></b-button>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted"> {{ totalStudentCount }} students </span>
          </b-col>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination aria-controls="StudentsListTableId" v-model="pagination.currentPage"
              :total-rows="totalStudentCount" :per-page="pagination.perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="onChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormSelectOption,
  BContainer,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
import CommonServices from "@/apiServices/CommonServices";
import { FILESURL } from "@/config";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import AgentServices from "@/apiServices/AgentServices";
import store from "@/store";
import moment from "@/utils/moment";
import SendEmailModal from "@/views/components/MyLeadComponents/SendEmailModal.vue";
import CounselorServices from "@/apiServices/CounselorServices";
import { UserService } from "@/apiServices/storageService";
import OperationsServices from "@/apiServices/OperationsServices";
import AccountsServices from "@/apiServices/AccountsServices";
import OperationsManagerServices from "@/apiServices/OperationsManagerServices";
import LmsServices from "@/apiServices/LmsServices";
import user from "@/store/user";
import { all_months, getAllYears } from "@/assets/json_data/data";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
    SendEmailModal,
    BContainer,
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let user_type = store.getters["user/getUserDetails"].user_type;
        if (user_type === "operations_manager") {
          this.getAllOpCounselors();
          this.getAllOperations();
          this.getAllVisa();
          this.getAllFranchises();
        }

        let temp_arr = this.tableColumns;

        if (user_type === "counselor") {
          this.getAllFranchises();
          this.getAllAssociates();
          let col_obj = {
            key: "assigned_staff",
            label: "Applications",
            sortable: false,
          };

          let service_commission_col = {
            key: "service_commission",
            label: "Services Commission",
            sortable: false,
          };

          let sa_col = {
            key: "student_applications",
            label: "Applications",
            sortable: false,
          };
          let aa_col = {
            key: "active_applications",
            label: "Active Applications",
            sortable: false,
          };

          if (!temp_arr.some((col) => col.key === col_obj.key)) {
            temp_arr.push(col_obj);
          }
          if (!temp_arr.some((col) => col.key === sa_col.key)) {
            temp_arr.push(sa_col);
          }
          if (!temp_arr.some((col) => col.key === aa_col.key)) {
            temp_arr.push(aa_col);
          }
          if (!temp_arr.some((col) => col.key === service_commission_col.key)) {
            temp_arr.push(service_commission_col);
          }

          this.tableColumns = [...this.tableColumns, ...this.sourceColumns];
        } else if (user_type === "services") {
          this.getAllCounselorsCommon();
          this.getAllFranchises();
          this.getAllAssociates();
          let col_obj = {
            key: "assigned_staff",
            label: "Applications",
            sortable: false,
          };

          let plan_obj = {
            key: "plan_name_with_service",
            label: "Service",
            sortable: false,
          };

          let transaction_amount_col_obj = {
            key: "transaction_amount",
            label: "Transaction Amount",
            sortable: false,
          };

          if (!temp_arr.some((col) => col.key === col_obj.key)) {
            temp_arr.push(col_obj);
          }
          if (!temp_arr.some((col) => col.key === plan_obj.key)) {
            temp_arr.push(plan_obj);
          }
          if (
            !temp_arr.some((col) => col.key === transaction_amount_col_obj.key)
          ) {
            temp_arr.push(transaction_amount_col_obj);
          }

          this.tableColumns = this.tableColumns.filter(
            (col) =>
              col.key !== "student_applications" &&
              col.key !== "active_applications"
          );

          this.tableColumns = [...this.tableColumns, ...this.sourceColumns];
        } else if (
          ["operations", "visa", "operations_manager"].includes(user_type)
        ) {
          this.getAllFranchises();
          this.getAllAssociates();
          let col_obj = {
            key: "assigned_staff",
            label: "Counselor",
            sortable: false,
          };
          let sa_col = {
            key: "student_applications",
            label: "Applications",
            sortable: false,
          };
          let aa_col = {
            key: "active_applications",
            label: "Active Applications",
            sortable: false,
          };

          if (!temp_arr.some((col) => col.key === col_obj.key)) {
            temp_arr.push(col_obj);
          }
          if (!temp_arr.some((col) => col.key === sa_col.key)) {
            temp_arr.push(sa_col);
          }
          if (!temp_arr.some((col) => col.key === aa_col.key)) {
            temp_arr.push(aa_col);
          }

          this.tableColumns = [...this.tableColumns, ...this.sourceColumns];
          if (user_type === "operations_manager") {
            let operations_col = {
              key: "operations_name",
              label: "Operations",
              sortable: false,
            };
            let visa_col = {
              key: "visa_name",
              label: "Visa",
              sortable: false,
            };
            if (!temp_arr.some((col) => col.key === operations_col.key)) {
              temp_arr.push(operations_col);
            }
            if (!temp_arr.some((col) => col.key === visa_col.key)) {
              temp_arr.push(visa_col);
            }
            this.tableColumns = [...temp_arr, ...this.sourceColumns];
          }

          this.getAllCounselors();
        } else if (["franchise"].includes(user_type)) {
          let fr_invoice = {
            key: "fr_invoice",
            label: "Commission Invoice",
            sortable: false,
          };
          // if (!temp_arr.some((col) => col.key === fr_invoice.key)) {
          //   temp_arr.push(fr_invoice);
          // }
          this.tableColumns = [...this.tableColumns, ...temp_arr];
          this.tableColumns = [...this.tableColumns];
        } else if (user_type === "accounts") {
          this.getAllCounselorsCommon();
          this.getAllFranchises();
          this.getAllApplicationStatuses();
          this.getInvoiceStatus();
          let university_commission_col = {
            key: "university_commission",
            label: "University Commission",
            sortable: false,
          };
          let franchise_commission_col = {
            key: "franchise_commission",
            label: "Franchise Commission",
            sortable: false,
          };
          let fr_commission_date_col = {
            key: "fr_commission_date",
            label: "Commission Date",
            sortable: false,
          };
          let application_status_obj = {
            key: "status_name",
            label: "Application status",
            sortable: false,
          };
          let transaction_datetime_obj = {
            key: "transaction_datetime",
            label: "Payment Date",
            sortable: false,
          };
          let fr_invoice_obj = {
            key: "fr_invoice",
            label: "Franchise Invoice",
            sortable: false,
          };
          let uni_invoice_obj = {
            key: "uni_invoice",
            label: "University Invoice",
            sortable: false,
          };
          if (!temp_arr.some((col) => col.key === application_status_obj.key)) {
            temp_arr.push(application_status_obj);
          }
          if (
            !temp_arr.some((col) => col.key === fr_commission_date_col.key)
          ) {
            temp_arr.push(fr_commission_date_col);
          }
          if (
            !temp_arr.some((col) => col.key === franchise_commission_col.key)
          ) {
            temp_arr.push(franchise_commission_col);
          }
          if (
            !temp_arr.some((col) => col.key === transaction_datetime_obj.key)
          ) {
            temp_arr.push(transaction_datetime_obj);
          }
          // if (!temp_arr.some((col) => col.key === uni_invoice_obj.key)) {
          //   temp_arr.push(uni_invoice_obj);
          // }
          // if (!temp_arr.some((col) => col.key === fr_invoice_obj.key)) {
          //   temp_arr.push(fr_invoice_obj);
          // }


          this.tableColumns = this.tableColumns.filter(
            (col) => col.key !== "conversion_date"
          );

          this.tableColumns = [...this.tableColumns, ...this.sourceColumns];
        }

        return user_type;
      } else {
        return null;
      }
    },
    show_bulk_actions() {
      return this.studentsList.filter((lead) => lead.is_selected).length > 0;
    },
  },
  watch: {
    user_type(newValue, oldValue) {
      if (this.user_type === "counselor") {
        this.getAllLeadSourceOptions();
      }
    },
  },
  data() {
    return {
      incr: 0,
      FILESURL,
      searchQuery: null,
      creator_user_id: null,
      studentsList: [],
      fullStudentList: [],
      totalStudentCount: 0,
      student_source: [],
      leadType: null,
      leadSource: null,
      leadTypeOptions: [
        { label: "Direct", value: "direct" },
        { label: "Associate", value: "agent" },
        { label: "Franchise", value: "franchise" },
      ],
      leadSourceOptions: [],

      leadDirectSourceOptions: [],
      leadAgentSourceOptions: [],
      leadFranchiseSourceOptions: [],
      // leadAgentSourceOptions: [],
      sourceColumns: [
        {
          key: "student_source_type",
          label: "Source Type",
          sortable: false,
        },
        { key: "student_source", label: "Source", sortable: false },
      ],
      tableColumns: [
        { key: "user_id", label: "Id", sortable: false },
        { key: "user_name", label: "Name", sortable: false },
        { key: "user_mobile", label: "Phone", sortable: false },

        { key: "student_status", label: "Status", sortable: false },

        { key: "conversion_date", label: "Conversion", sortable: false },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      student_status_options: [
        { label: "Active", value: "active" },
        { label: "Inactive", value: "inactive" },
        { label: "Hold", value: "hold" },
        { label: "Joined", value: "joined" },
      ],
      status: [
        { label: "Active", value: "Y" },
        { label: "Unactive", value: "N" },
      ],
      conversion_status_options: [
        { label: "Converted", value: "Y" },
        { label: "Not Converted", value: "N" },
      ],
      counselor_options: [],
      student_form: {
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        mobile: null,
      },
      lead_form: {
        // full_name: "",
        // email: "",
        // mobile: "",
        stage_id: 5,
        category_id: 1,
      },

      filters: {
        student_status: null,
        conversion_status: null,
        counselor_id: null,
        search: null,
        conversion: null,
        operations_id: null,
        visa_id: null,
        franchise_id: null,
        agent_id: null,
        service_id: null,
        application_status_id: null,
        intake_month: '',
        its_id: '',
        service_completion_status: null
      },
      university_commission: null,
      franchise_commission: null,
      passwordFieldType: "password",
      required,
      email,
      dateRange: "",
      debounce: null,
      op_counselors: [],
      op_operations: [],
      op_franchises: [],
      op_agents: [],
      op_visa: [],
      services_list: [],
      application_statuses: [],

      //bulk email leads
      allLeads: [],
      selected_lead: null,
      selected_lead_ids: [],
      selected_lead_emails: [],
      selectable_lead_ids: [],
      select_all: false,
      lmsApi: null,
      emailDetails: {
        emailModalOpen: false,
        email: "",
      },

      bulkEmailDetails: {
        emailModalOpen: false,
        email: "",
      },
      show_working_invoice: false,
      show_franchise_invoice: false,
      workingInvoice: null,
      bank_accounts: [],
      all_months,
      invoice_statuses: [],
    };
  },
  directives: {
    BTooltip,
  },
  methods: {
    calculateTotalCommission() {

    },
    getWhatsappFormattedMobile(mobile) {
      let finalNumber = [];
      mobile = mobile.split("");
      mobile.forEach((number) => {
        let n = parseInt(number);
        if (!isNaN(n)) {
          finalNumber.push(n);
        }
      });

      return finalNumber.join("");
    },
    moment,
    row_clicked(e) {
      this.$router.push({
        name: "Student Details",
        params: { student_user_id: e.student_user_id },
      });
    },
    async getAllOpCounselors() {
      try {
        const response = await OperationsManagerServices.getAllCounselors();

        if (response.data.status) {
          this.op_counselors = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    async getAllCounselorsCommon() {
      try {
        const response = await CommonServices.getAllCounselorsCommon();

        if (response.data.status) {
          this.counselor_options = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },

    async getAllOperations() {
      try {
        const response = await OperationsManagerServices.getAllOperations();

        if (response.data.status) {
          this.op_operations = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    async getAllFranchises() {
      try {
        const response = await CommonServices.getAllFranchises();

        if (response.data.status) {
          this.op_franchises = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    async getAllAssociates() {
      try {
        const response = await CommonServices.getAllAssociates();

        if (response.data.status) {
          this.op_agents = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    async getAllVisa() {
      try {
        const response = await OperationsManagerServices.getAllVisa();

        if (response.data.status) {
          this.op_visa = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    getLeadData() {
      return {
        leadType: this.leadType,
        leadSource: this.leadType == "direct" ? this.leadSource : null,
        leadSourceUserId: this.leadType !== "direct" ? this.leadSource : null,
      };
    },
    getNameFromUserId(sourceType, id) {
      if (!sourceType && !id) return "-";
      const currentSources = this.getCurrentSourceOptions(sourceType);
      const currentSource = currentSources.find(
        (source) => source.value === id
      );

      return currentSource?.label;
    },
    getLeadTypeFromKey(key) {
      if (!key) return "-";
      const currentLeadType = this.leadTypeOptions.find(
        (leadType) => leadType.value === key
      );

      return currentLeadType.label;
    },
    getDirectSourceByValue(value) {
      if (!value) return "-";
      const directLeadType = this.leadDirectSourceOptions.find(
        (directLeadSource) => directLeadSource.value === value
      );

      if (!directLeadType) return "-";

      return directLeadType.label;
    },
    getDateRangeParams() {
      if (this.$route.query.dateRange) {
        this.dateRange = this.$route.query.dateRange;
      }
    },
    getSourceUser(user_id) {
      return user_id;
    },
    async getAllLeadSourceOptions() {
      this.leadDirectSourceOptions = [
        {
          label: "Facebook",
          value: "facebook",
        },
        {
          label: "Instagram",
          value: "instagram",
        },
        {
          label: "Aliff Website",
          value: "aliffWebsite",
        },
        {
          label: "Alumni",
          value: "alumni",
        },
      ];

      if (this.user_type === "counselor") {
        let res = await CounselorServices.getAssignedAgents({
          leadUserTypes: ["agent", "franchise"],
        });
        const allOptions = res.data.data;
        this.leadAgentSourceOptions = [];
        this.leadFranchiseSourceOptions = [];

        for (let option of allOptions) {
          if (option.user_type_key === "agent") {
            this.leadAgentSourceOptions.push({
              label: option.user_firstname + " " + option.user_lastname,
              value: option.user_id,
            });
          } else if (option.user_type_key === "franchise") {
            this.leadFranchiseSourceOptions.push({
              label: option.user_firstname + " " + option.user_lastname,
              value: option.user_id,
            });
          }
        }
      }

      if (
        this.user_type === "operations" ||
        this.user_type === "operations_manager"
      ) {
        let res = await OperationsServices.getLeads();

        const allOptions = res.data.data;

        this.leadAgentSourceOptions = [];
        this.leadFranchiseSourceOptions = [];

        for (let option of allOptions) {
          if (option.user_type_key === "agent") {
            this.leadAgentSourceOptions.push({
              label: option.user_firstname + " " + option.user_lastname,
              value: option.user_id,
            });
          } else if (option.user_type_key === "franchise") {
            this.leadFranchiseSourceOptions.push({
              label: option.user_firstname + " " + option.user_lastname,
              value: option.user_id,
            });
          }
        }
      }
    },
    getCurrentSourceOptions(sourceType) {
      if (sourceType == "direct") {
        return this.leadDirectSourceOptions;
      } else if (sourceType == "agent") {
        return this.leadAgentSourceOptions;
      } else if (sourceType == "franchise") {
        return this.leadFranchiseSourceOptions;
      }
      return [];
    },

    conversion_date_fn(date) {
      if (date && moment(date).isValid()) {
        return moment(date).format("DD-MM-YYYY");
      } else {
        return "No";
      }
    },
    async setStudentStatus(status, student_profile) {
      try {
        const response = await CommonServices.setStudentStatus({
          student_user_id: student_profile.student_user_id,
          status,
        });
        if (response.data.status) {
          student_profile.is_close = status;
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Status Changed",
              icon: "EditIcon",
              variant: "success",
            },
          });
        } else {
          this.getAssignedStudentsList();
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Status not Changed",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        this.getAssignedStudentsList();
        console.error("Error in setStudentStatus ", err);
      }
    },
    onChange() {
      this.$nextTick(() => {
        this.getAssignedStudentsList();
      });
    },
    onSearch() {
      this.pagination.currentPage = 1;
      if (this.debounce) {
        clearTimeout(this.debouce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.onChange();
      }, 1000);
    },
    onType(id, commission, franchise , date) {
      if (this.debounce) {
        clearTimeout(this.debouce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        if (this.user_type === "accounts") {
          this.saveUniAndFranchiseCom(id, commission, franchise, date);
        }
        if (this.user_type === "counselor") {
          this.saveServiceCommission(id, commission);
        }
      }, 2000);
    },
    onCommissionType() {
      if (this.debounce) {
        clearTimeout(this.debouce);
        this.debounce = null;
      }
      this.debounce = setTimeout(() => {
        this.updateCommissionInInvoice();
      }, 2000);
    },
    async updateCommissionInInvoice() {
      try {
        const res = await CommonServices.updateCommissionInInvoice({
          inv_id: this.workingInvoice.inv_id,
          franchise_gross_commission: this.workingInvoice.franchise_gross_commission,
          franchise_net_commission: this.workingInvoice.commission.franchise_commission,
          accounts_net_commission: this.workingInvoice.commission.total_commission,
          accounts_net_commission_inr: this.workingInvoice.commission.inr_commission,
        });
        if (res.data.status) {
          this.getWorkingInvoice(workingInvoice.student_user_id);
        }
      } catch (error) {
        console.error("Error getting updateCommissionInInvoice ", error);
      }
    },

    async getAssignedStudentsList() {
      try {
        const student_ids = JSON.parse(
          localStorage.getItem("student_user_ids")
        );
        const response = await CommonServices.getAssignedStudents({
          ...this.filters,
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          lead: this.getLeadData(),
          dateRange: this.dateRange,
          student_ids: student_ids ? student_ids : [],
        });
        if (response.data.status) {
          this.studentsList = response.data.data.data;
          this.fullStudentList = response.data.data.data;
          this.totalStudentCount = response.data.data.count;
          // this.selectable_lead_ids = studentsList.map((l) => l.user_id);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Error fetching Students",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (error) {
        console.error("Error getting assigned students ", error);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error fetching Students",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },
    async saveUniAndFranchiseCom(id, commission, franchise, date) {
      const obj = {
        university_commission: commission,
        franchise_commission: franchise,
        fr_commission_date: date,
        student_user_id: id,
      };
      const res = await AccountsServices.saveCommissionInStudent(obj);
      if (res.data.status) {
        this.getAssignedStudentsList();
      }
    },
    async saveServiceCommission(id, commission) {
      const obj = {
        service_commission: commission,
        student_user_id: id,
      };
      const res = await CounselorServices.saveServiceCommission(obj);
      if (res.data.status) {
        this.getAssignedStudentsList();
      }
    },
    async getAllCounselors() {
      try {
        const response = await OperationsServices.getAllCounselors();

        if (response.data.status) {
          this.counselor_options = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllCounselors ", error);
      }
    },
    async getAllApplicationStatuses() {
      try {
        const response = await CommonServices.getAllApplicationStatuses();
        if (response.data.status) {
          let status = response.data.data;
          this.application_statuses = status.slice(3, 8);
        }
      } catch (error) {
        console.error("Error getting getAllApplicationStatuses ", error);
      }
    },
    // setStudentSourceFilter() {
    //   this.student_source = [{ label: "website", value: "website" }];
    //   const pushed_values = [];
    //   for (let i = 0; i < this.fullStudentList.length; i++) {
    //     const student = this.fullStudentList[i];
    //     if (student.creator_user_id) {
    //       if (!pushed_values.includes(student.creator_user_id)) {
    //         pushed_values.push(student.creator_user_id);
    //         this.student_source.push({
    //           label: `${student.creator_firstname} ${student.creator_lastname}`,
    //           value: student.creator_user_id,
    //         });
    //       }
    //     }
    //   }
    // },
    // onSearch() {
    //   this.studentsList = this.fullStudentList.filter((student, index) => {
    //     let full_name = student.user_firstname + " " + student.user_lastname;
    //     let text = this.searchQuery;
    //     let text_search = true;

    //     if (text) {
    //       text_search =
    //         full_name.toLowerCase().includes(text.toLowerCase()) ||
    //         student.user_email.toLowerCase().includes(text.toLowerCase()) ||
    //         student.user_id
    //           .toString()
    //           .toLowerCase()
    //           .includes(text.toLowerCase()) ||
    //         student.user_mobile.toLowerCase().includes(text.toLowerCase());
    //     }
    //     let source = true;
    //     if (this.creator_user_id) {
    //       if (this.creator_user_id === "website") {
    //         source = student.creator_user_id === null ? true : false;
    //       } else if (!isNaN(this.creator_user_id)) {
    //         source =
    //           student.creator_user_id === this.creator_user_id ? true : false;
    //       }
    //     }
    //     let conversion = true;
    //     if (this.filters.conversion_status) {
    //       if (this.filters.conversion_status === "Y") {
    //         if (student.is_converted && student.is_converted === "Y") {
    //           conversion = true;
    //         } else {
    //           conversion = false;
    //         }
    //       } else if (this.filters.conversion_status === "N") {
    //         if (!student.is_converted || student.is_converted === "N") {
    //           conversion = true;
    //         } else {
    //           conversion = false;
    //         }
    //       }
    //     }
    //     return text_search && source && conversion;
    //   });
    // },

    student_applications_count(val) {
      if (val && val.length) {
        return val.split(",").length;
      } else {
        return 0;
      }
    },
    resetFilters() {
      this.filters = {
        student_status: null,
        conversion_status: null,
      };
      this.leadType = null;
      this.leadSource = null;
      this.searchQuery = "";
      this.$router.push("/student_list");
      this.dateRange = "";
      this.getAssignedStudentsList();
    },
    resetAddStudentModal() {
      this.student_form = {
        email: null,
        password: null,
        firstname: null,
        lastname: null,
        mobile: null,
      };
    },
    async addStudent(e) {
      // e.preventDefault();
      this.$refs.addStudentForm.validate().then(async (success) => {
        if (success) {
          try {
            const response = await AgentServices.addStudent(this.student_form);

            if (response.data.status) {
              this.getAssignedStudentsList();
              this.$store.dispatch("user/onGetAssignedStudents");
              this.$refs["add-student-modal"].hide();
            }
            this.saveLeadForm();
          } catch (err) {
            console.error("Error in Adding Student ", err);
          }
        }
      });
    },
    async saveLeadForm() {
      try {
        const res = await this.lmsApi.saveLead({
          ...this.lead_form,
          full_name: `${this.student_form.firstname} ${this.student_form.lastname}`,
          email: this.student_form.email,
          mobile: this.student_form.mobile,
        });

        if (!res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Failed to save lead",
              icon: "X",
              variant: "failure",
            },
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Lead Saved",
              icon: "Check",
              variant: "success",
            },
          });
          this.$router.back();
        }
      } catch (error) {
        console.error("Error in saveLeadForm ", error);
      }
    },
    closeModal() {
      this.$refs["add-student-modal"].hide();
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    async saveLead(user_id, applicationLimit, leadType, leadSource) {
      try {
        const response = await CounselorServices.saveProfileExtraInfo({
          application_limit: applicationLimit,
          student_user_id: user_id,
          source_type: leadType,
          source: leadType && leadType == "direct" ? leadSource : null,
          source_user_id: leadType && leadType != "direct" ? leadSource : null,
        });
        this.getAssignedStudentsList();
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Lead Option Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
        }
      } catch (err) {
        console.error("Error in saving application limit  ", err);
      }
    },
    async getServicesAndPlans() {
      const res = await CommonServices.getServicesAndPlans();
      if (res.data.status) {
        this.services_list = res.data.data;
      }
    },

    selectOrDeselectAll() {
      if (this.select_all) {
        this.selected_lead_ids = this.studentsList.map((lead) => lead.user_id);
        this.selected_lead_emails = this.studentsList.map(
          (lead) => lead.user_email
        );
        this.studentsList = this.studentsList.map((lead) => ({
          ...lead,
          is_selected: true,
        }));
      } else {
        this.selected_lead_ids = [];
        this.selected_lead_emails = [];
        this.studentsList = this.studentsList.map((lead) => ({
          ...lead,
          is_selected: false,
        }));
      }
    },

    selectOrDeselectSingle(lead) {
      if (lead.is_selected) {
        this.selected_lead_ids.push(lead.user_id);
        this.selected_lead_emails.push(lead.user_email);
        this.studentsList = this.studentsList.map((l) =>
          lead.user_id === l.user_id ? { ...l, is_selected: true } : l
        );

        if (this.selectable_lead_ids.length === this.selected_lead_ids.length) {
          this.select_all = true;
        }
      } else {
        this.select_all = false;
        this.selected_lead_ids = this.selected_lead_ids.filter(
          (l) => l !== lead.user_id
        );
        this.selected_lead_emails = this.selected_lead_emails.filter(
          (email) => email !== lead.user_email
        );
        this.studentsList = this.studentsList.map((l) =>
          lead.user_id === l.user_id ? { ...l, is_selected: false } : l
        );
      }
    },
    markSelectedLeads() {
      this.studentsList = this.studentsList.map((l) =>
        this.selected_lead_ids.includes(l.lead_id)
          ? { ...l, is_selected: true }
          : l
      );
    },
    async showBulkEmailModal() {
      // const res = await this.lmsApi.getEmailByLead({
      //   lead_ids: Array.from(new Set(this.selected_lead_ids)),
      // });
      // if (!res.data.status) {
      //   toast.error("An Unexpected error has occured");
      //   return;
      // }
      this.bulkEmailDetails.email = this.selected_lead_emails;
      //   .filter((e) => this.checkEmail(e))
      //   .join("\n");
      console.log(this.selected_lead_emails);
      this.bulkEmailDetails.emailModalOpen = true;
    },
    async sendBulkMail(data) {
      try {
        const res = await CommonServices.sendBulkMail({
          ...data,
        });
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Emails Sent Successfully",
              icon: "Check",
              variant: "success",
            },
          });
          return;
        }
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to send Emails",
            icon: "X",
            variant: "failure",
          },
        });
      } catch (error) {
        console.log({ error });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Failed to send Emails",
            icon: "X",
            variant: "failure",
          },
        });
      }
    },
    showEmailModal(email) {
      this.emailDetails = {};
      this.emailDetails.email = email;
      this.emailDetails.emailModalOpen = true;
    },

    checkEmail(email) {
      const emailRegex = /\S+@\S+\.\S+/;
      if (emailRegex.test(email)) {
        return true;
      }
      return false;
    },

    closeEmailModal() {
      this.emailDetails.emailModalOpen = false;
      this.bulkEmailDetails.emailModalOpen = false;
      this.$forceUpdate();
    },

    moment,
    async getLMSAccess() {
      try {
        // this.isLoading = true;
        const res = await CommonServices.getLMSAccessToken();
        this.accessToken = res.data.data;

        if (!this.accessToken) {
          this.accessTokenFailed = true;
          return;
        }

        this.lmsApi = LmsServices(this.accessToken);
      } catch (error) {
        console.error(`Error in getLMSAccess `, error);
      }
    },
    async showWorkingInvoice(student_user_id, source) {
      await this.getWorkingInvoice(student_user_id);
      // if (!this.workingInvoice) {
      //   this.$toast({
      //     component: ToastificationContentVue,
      //     props: {
      //       title: "No Invoice Found",
      //       icon: "X",
      //       variant: "danger",
      //     },
      //   });
      //   return
      // }
      if (
        this.workingInvoice &&
        this.workingInvoice.its_id === 1 &&
        source === "accounts"
        // this.user_type === "accounts"
      ) {
        this.show_working_invoice = true;
        return
      } else if (
        this.workingInvoice &&
        this.workingInvoice.its_id < 5 &&
        source === "franchise"
        // this.user_type === "franchise"
      ) {
        if (this.user_type === "franchise") {

          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Invoice not ready",
              icon: "X",
              variant: "danger",
            },
          });
        } else {
          this.show_working_invoice = true;

        }

        return
      }
      else {
        this.$router.push({
          name: "University Invoice",
          params: { student_user_id: student_user_id },
          query: { franchise: true },
        });
      }
    },
    async showFranchiseWorkingInvoice(student_user_id) {
      if(this.user_type === 'accounts'){
        await this.getFranchiseWorkingInvoice(student_user_id);
        if (
          this.workingInvoice &&
          this.workingInvoice.its_id <= 6
        ) {
            this.show_franchise_invoice = true;
  
          return
        }
      }
      else {
        this.$router.push({
          name: "University Invoice",
          params: { student_user_id: student_user_id },
          query: { franchise: true },
        });
      }
    },
    async getWorkingInvoice(student_user_id) {
      try {
        let res = [];
          res = await AccountsServices.getWorkingInvoice({
            student_user_id,
          });
        
        if (res.data.status) {
          this.workingInvoice = res.data.data;
          if (this.user_type === "accounts") {
            this.getBankAccounts();
          } else {
            return;
          }
        }
      } catch (error) {
        console.error("Error getting working invoice ", error);
      }
    },
    async getFranchiseWorkingInvoice(student_user_id) {
      try {
        let res = [];
          res = await AccountsServices.getWorkingInvoice({
            student_user_id,
            source: "franchise"
          });
        
        if (res.data.status) {
          this.workingInvoice = res.data.data;
          if (this.user_type === "accounts") {
            this.getBankAccounts();
          } else {
            return;
          }
        }
      } catch (error) {
        console.error("Error getting working invoice ", error);
      }
    },

    async getBankAccounts() {
      try {
        // if (this.user_type === "franchise") {
        //   res = await AccountsServices.getWorkingInvoice({
        //     student_user_id,
        //   })
        // }
        // if (this.user_type === "franchise") {
        //   res = await AccountsServices.getWorkingInvoice({
        //     student_user_id,
        //   });
        // }
        const res = await CommonServices.getAllBanks();
        if (res.data.status) {
          this.bank_accounts = res.data.data;
        }
      } catch (error) {
        console.error("Error getting bank accounts ", error);
      }
    },
    async updateInvoice(invoice) {
      try {
        if(!invoice.ba_id && invoice.its_id === 1){
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Bank Account Not Selected",
              icon: "X",
              variant: "failure",
            },
          });

          return;
        }
        let res = [];
        if (this.user_type === "accounts") {
          res = await AccountsServices.updateWorkingInvoice({
            inv_id: invoice.inv_id,
            its_id: 3,
            ba_id: invoice.ba_id,
          });
        }
        if (this.user_type === "franchise") {
          res = await AgentServices.updateWorkingInvoice({
            inv_id: invoice.inv_id,
            its_id: 5,
            // ba_id: invoice.ba_id,
          });
        }
        if (res.data.status) {
          this.show_working_invoice = false;
          this.getWorkingInvoice(invoice.student_user_id);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Invoice Approved",
              icon: "Check",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.error("Error updating invoice ", error);
      }
    },
    async updateFranchiseInvoice(invoice){
      try {
        let res = [];
        
        res = await AccountsServices.updateWorkingInvoice({
          inv_id: invoice.inv_id,
          its_id: invoice.its_id === 4 ? 5 : 6,
          invoice : invoice
          // ba_id: invoice.ba_id,
        });
        if (res.data.status) {
          this.show_working_invoice = false;
          this.getFranchiseWorkingInvoice(invoice.student_user_id);
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Invoice Approved",
              icon: "Check",
              variant: "success",
            },
          });
        }
      } catch (error) {
        console.error("Error updating invoice ", error);
      }
    },

    async getClubbedInvoice() {
      try {
        const res = await CommonServices.getClubbedInvoice({
          student_user_ids: this.selected_lead_ids
        })
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Clubbed Invoice Generated",
              icon: "Check",
              variant: "success",
            },
          });
          this.$router.push({
            name: "University Invoice",
            params: { student_user_id: this.selected_lead_ids.split(",") },
          });
        }
      } catch (error) {
        console.error("Error getting clubbed invoice ", error);
      }
    },
    async getInvoiceStatus() {
      try {
        const res = await AccountsServices.getInvoiceStatuses()
        if (res.data.status) {
          this.invoice_statuses = res.data.data
        }
      } catch (error) {
        console.log("Error getting invoice statuses ", error)
      }
    },
  },
  beforeMount() {
    if (
      this.$route.query.conversion ||
      this.$route.query.application_status_id ||
      this.$route.query.student_status
    ) {
      this.filters.conversion = this.$route.query.conversion;
      this.filters.application_status_id =
        this.$route.query.application_status_id;
      this.filters.student_status = this.$route.query.student_status;
      this.getAssignedStudentsList();
    }
    this.getDateRangeParams();
    this.getServicesAndPlans();
    this.getAssignedStudentsList();
    this.getAllLeadSourceOptions();
    this.getLMSAccess();

  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
